*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
  font-family: sans-serif;
}

button,
input {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
}

/* navbar */
.nav-bar {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.sign-in {
  border: none;
  background-color: transparent;
}
.sign-in > img {
  height: 30px;
  width: auto;
}
.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid;
  font-weight: 600;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

/* chat component */
.messages-wrapper {
  padding: 30px;
  margin-bottom: 60px;
}
.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 15px;
  width: max-content;
  max-width: calc(100% - 50px);
  box-shadow: -2px 2px 1px 1px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  box-shadow: -2px 2px 1px 1px;
}
.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.user-message {
  word-break: break-all;
}
.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
}
.send-message > input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  font-size: 1rem;
}
.send-message > input:placeholder {
  color: #242443;
}
.send-message > :is(input, button):focus {
  outline: none;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  border: 1px solid;
  font-weight: 600;
}
